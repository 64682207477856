/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from "react"

const Content = ({ pieces, title, info, id }) => {
  function createMarkup(content) {
    return { __html: content }
  }

  const [ items, setItems ] = useState(pieces.map(item => ({
      ...item,
      isOpen: false
  })))

  return (
    <div className="content">
      <h3 id={id} className="title is-4 is-spaced c-anchor-title">
        <span className="c-anchor-name">{title}</span>
        <a className="c-anchor-link" href={`#${id}`}>
          #
        </a>
      </h3>
      {info && (
        <p
          className="has-text-weight-light mb-5"
          dangerouslySetInnerHTML={createMarkup(info)}
        />
      )}

      {items.map(({ title, content, isOpen }) => (
        <div key={title} className="content">
          <p className="has-text-weight-bold is-clickable is-size-6" onClick={e => setItems(
              items.map(item => item.title === title 
                ? {
                    ...item,
                    isOpen: !item.isOpen
                } : item 
            )
          )}>
            <svg
              xmlns="https://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="#2572e4"
              className="is-inline-block"
              style={{
                  verticalAlign: 'bottom'
              }}
            >
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
            {title}
          </p>
          <div
            dangerouslySetInnerHTML={createMarkup(content)}
            className={isOpen ? '' : 'is-hidden'}
          />
          <hr />
        </div>
      ))}
    </div>
  )
}

export default Content
