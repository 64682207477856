// import React, { useEffect } from "react"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Wrapper from "../components/docker-for-developers/wrapper"
import Sidemenu from "../components/docker-for-developers/sidemenu"
import Content from "../components/docker-for-developers/content"
import ApplicationForm from "../components/docker-for-developers/form"
import { classes, faq } from "../components/docker-for-developers/data"

const DockerForDevelopersPage = () => {
  // useEffect(() => {
  //   const script = document.createElement("script")
  //   script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_CLIENT_KEY}`
  //   document.body.appendChild(script)
  // }, [])

  const meta = {
    bodyAttributes: {
      class: "c-recaptcha",
    },
    lang: "mk",
    image: "https://stojkov.me/docker_for_developers.jpg",
    url: "https://stojkov.me/docker-for-developers/",
    title: "Docker for Developers: курс за програмери",
    description:
      "Научете Docker и како да го користите за да го забрзате и олесните процесот на развивање на апликации.",
  }

  return (
    <Layout hasHeader={false}>
      <Seo {...meta} />

      <Wrapper>
        <Sidemenu />

        <div className="c-course-lead">
          <Content {...classes} />
          <ApplicationForm />
          <Content {...faq} />
        </div>
      </Wrapper>
    </Layout>
  )
}

export default DockerForDevelopersPage
