export const classes = {
  title: `Програма`,
  id: `course-content`,
  info: `
    <small>
      <i>
        * Пред да започнеме со официјалната програма на курсот прикажана тука, ќе имаме еден воведен час (во договор со учесниците, неделата пред да започнеме) со цел да се запознаеме сите учесници, да се осигураме дека сите сме спремни за непречено и квалитетно одвивање на курсот т.е. имаме инсталирано <i>Docker</i> и сѐ што ќе ни биде потребно во текот на овој курс.
      </i>
    </small>`,
  pieces: [
    {
      title: "Што е Docker?",
      content: `Ќе нурнеме во курсот со одговарање на прашањето 
                      <i>Што е <em>Docker</em> и зошто би го користеле?</i> Kога зборуваме за
                      <i>Docker</i> зборуваме за платформа која се состои од множество од
                      алатки кои впрегнати заедно ни го даваат она што го знаеме како
                      <i>Docker</i>. Ќе ги разгледаме сите тие алатки и ќе видеме како заедно на едноставен начин ни помагаат да ги решиме сложените проблеми.`,
    },
    {
      title: "Docker Containers",
      content: `Во сржта на <i>Docker</i> се контејнерите. Совладувањето на <i>Docker</i>
                      подразбира солидно знаење и искуство со управување на
                      контејнери: како да ги создадеме, кренеме, набљудуваме,
                      сопреме... Во текот на овој курс ќе се запознаеме и ќе стремиме
                      кон комфорност со извршување на <i>Docker</i> команди од терминал. Ќе
                      почнеме од основите, така што сосема е во ред ако терминалот и
                      извршувањето команди не ни се најсилната страна.`,
    },
    {
      title: `Docker Images`,
      content: `Секој контејнер е дефиниран од <i>Docker Image</i> во кој се
                      состојат сите инструкции за креирање и извршување на истиот. Ќе
                      разгледаме како и од каде да ги пронајдеме, како да ги
                      споделуваме со нашиот тим и со пошироката јавност, и конечно
                      како да го користиме <i>Dockerfile</i> за да креираме наш
                      сопствен <i>Docker Image</i>.`,
    },
    {
      title: `Container Storage and Networking`,
      content: `Во најосновната форма контејнерите се процеси кои се извршуваат со
                      минимални предодредени ресурси. Во тоа се вклучени и складишниот
                      простор за податоци, како и вмрежувањето на
                      контејнерите. Но зависно од специфичните потреби за
                      апликативните податоци и нивната достапност во и надвор од
                      контејнерот, како и барањата за вмрежување и комуникација со надворешноста и со останати контејнери,
                      <i>Docker</i> нуди можности за детално нагодување според соодветните
                      потреби. Ќе научиме како да го нагодиме за да ги задоволиме тие потреби.`,
    },
    {
      title: `Docker Compose`,
      content: `<i>Docker Compose</i> е алатка за дефинирање и извршување на
                      повеќеконтејнерски <i>Docker</i> апликации. Со <i>Compose</i> 
                      користиме <i>YAML</i> датотека за да ги дефинираме сервисите од
                      нашата апликација со саканите нагодувања, и со една команда ги
                      создаваме и креваме сите дефинирани сервиси. <i>Compose</i> е
                      мошне корисен и напреден концепт кој многу лесно може да го
                      применуваме на сите околини:
                      <i>production, staging, development, testing, </i> како и кај 
                      <i>CI workflows</i>.`,
    },
    {
      title: `Workshop: Docker Project`,
      content: `Сите претходни часови од текот на неделата се еден вид
                      подготвка за она што треба да биде врвот на овој курс: работилница
                      со работа на проект. Не само со <i>Docker</i>, туку и со која било
                      технологија, вистинското знаење се стекнува со работa и                       искуство. Затоа во текот на викендот ќе работиме на проект
                      користејќи го сето она што сме го научиле претходно. Ќе пишуваме и код, но
                      главниот фокус ќе ни биде на <i>Docker</i>.`,
    },
  ],
}

export const faq = {
  title: `ЧПП`,
  id: `faq`,
  info: `<i>
    * Во оваа секција се одговорите на прашањата кои можеби ги имате. Ве молам пред да се пријавите или да ме контактирате најпрво прочитајте ги внимателно.
  </i>`,
  pieces: [
    {
      title: "Кој може да се пријави на курсот?",
      content: `Задолжителен услов е учесниците да знаат и да имаат искуство во програмирање во кој било програмски јазик (впрочем затоа и курсот е насловен <i>Docker for Developers</i>). Исто така очекувам кандидатите/ките да бидат сериозни во намерата да научат <i>Docker</i>. Иако главниот фокус нема да ни биде на програмирањето, во текот на курсот ќе имаме потреба да пишуваме и читаме програмски код.`,
    },
    {
      title: "Што откако ќе се пријавам?",
      content: `Најпрвин потребно е да ја потврдите Вашата е-пошта — по пријавувањето ќе добиете е-порака за тоа. Потоа пријавата ќе биде разгледана во рок од неколку дена, и при позитивен резултат ќе добиете покана за краток разговор (интервју). По разговорот ќе бидете известени за конечниот исход.`,
    },
    {
      title: "Колку чини курсот?",
      content: `Овој курс е бесплатен. Го одржувам своеволно и со голем ентузијазам како спој на две работи кои многу ги засакав во последните неколку години: подучување и <em>Docker</em>. <a href="https://sourcemxacademy.mk/" target="_blank">SourceMX Academy</a> несебично даде на располагање ресурси за потребите на овој курс со коишто значително ќе го зголемиме квалитетот на часовите.`,
    },
    {
      title: "Колку ќе трае курсот?",
      content: `Ова ќе биде интензивен курс и ќе трае една недела, од 9ти до 15ти ноември. Часовите ќе траат по 3 часа со паузи: понеделник, среда и четврток, а во текот на викендот (сабота и недела) ќе имаме работилница (работа на проект) и ќе траат подолго зависно од текот на работата на проектот.`,
    },
    {
      title: "Кога ќе се одржуваат часовите?",
      content: `Со оглед на тоа дека веројатно сите ќе сме зафатени во текот на денот со тековни работни и други обврски, часовите ќе се одржуваат навечер (после работа) во периодот од 18:00 до 21:00, освен викендот кога часовите ќе почнуваат наутро.`,
    },
    {
      title: "Каде ќе се одржува курсот?",
      content: `Пред компјутер на <i>Zoom</i>.`,
    },
    {
      title: "Како ќе се избираат учесниците?",
      content: `На курсот ќе има најмногу осум учесници. Главните критериуми за избор ќе бидат желбата да се научи <em>Docker</em> и програмерското искуство. Исто така кандидатите/ките кои ќе се пријават порано ќе имаат предност т.е ако повеќе кандидати/ки имаат сличен профил, оние кои се пријавиле порано ќе бидат избрани да учествуваат на курсот.`,
    },
    {
      title: "Дали се издава сертификат за курсот?",
      content: `Не. Ако сте тука само за сертификат овој курс не е за Вас, Ве молам не се пријавувајте.`,
    },
  ],
}

