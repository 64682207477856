import React from "react"

const Wrapper = ({ children }) => (
  <>
    <section className="hero is-medium is-light mb-3">
      <div className="hero-body">
        <div className="container is-max-desktop">
          <div className="columns is-flex-direction-row-reverse">
            <div className="column has-text-centered">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="601px"
                height="431px"
                viewBox="0 0 6010 4310"
                preserveAspectRatio="xMidYMid meet"
                style={{
                  maxWidth: "250px",
                  width: "100%",
                  height: "auto",
                  display: "inline-block",
                  marginTop: '3.75rem'
                }}
              >
                <g id="layer101" fill="#2855a2" stroke="none">
                  <path d="M1905 4304 c-270 -17 -527 -58 -726 -113 -444 -125 -720 -357 -924 -776 -157 -322 -242 -690 -248 -1077 -2 -109 0 -127 20 -163 51 -95 113 -126 266 -133 l107 -5 0 -289 c0 -320 2 -331 63 -368 30 -19 52 -20 350 -20 l317 0 0 -287 c0 -324 3 -342 70 -375 32 -17 88 -18 722 -18 l687 0 3 -304 c3 -288 4 -305 23 -326 42 -47 64 -50 395 -50 345 0 364 3 400 62 19 32 20 49 20 666 l0 632 324 0 c320 0 323 0 357 23 58 39 59 44 59 365 0 280 1 292 19 292 70 0 316 -64 355 -93 16 -12 14 -19 -23 -97 -53 -111 -71 -183 -78 -306 -10 -176 27 -343 113 -517 41 -84 109 -187 122 -187 23 0 156 94 227 161 137 130 239 305 269 462 12 66 -1 62 166 47 241 -22 487 34 610 138 l35 30 -19 54 c-39 112 -143 254 -244 331 -159 123 -321 179 -561 195 l-103 7 -62 145 c-199 463 -410 788 -700 1075 -215 213 -421 358 -701 495 -334 163 -690 262 -1105 305 -146 15 -493 26 -605 19z" />
                </g>
                <g id="layer102" fill="#2496ed" stroke="none">
                  <path d="M1965 4203 c-522 -18 -962 -135 -1210 -321 -141 -104 -145 -98 62 -105 262 -8 442 -53 665 -165 100 -51 113 -60 116 -85 4 -30 -21 -67 -43 -67 -7 0 -54 22 -106 49 -105 55 -260 110 -389 138 -115 24 -506 27 -535 3 -32 -26 -135 -191 -185 -295 -129 -267 -211 -619 -227 -966 -7 -162 0 -189 57 -223 26 -15 194 -16 2022 -16 2144 0 2077 1 2268 -51 84 -23 200 -77 244 -113 l28 -24 -42 -62 c-151 -223 -165 -499 -39 -784 21 -50 48 -102 58 -116 l19 -24 41 28 c65 46 185 177 230 254 56 93 90 194 104 304 11 88 11 89 37 83 157 -33 359 -44 480 -25 81 13 207 55 239 79 l24 18 -34 64 c-41 77 -140 184 -217 236 -138 91 -287 132 -495 136 l-128 2 -50 125 c-69 172 -234 503 -314 630 -209 331 -490 622 -785 816 -315 207 -706 354 -1122 423 -250 42 -548 63 -773 54z m23 -963 c34 -21 52 -54 52 -95 0 -21 -4 -24 -27 -19 -41 8 -67 -16 -66 -60 1 -41 -8 -44 -61 -22 -45 19 -66 52 -66 102 0 91 90 142 168 94z" />
                  <path d="M537 2020 l-27 -21 0 -245 c0 -231 1 -245 20 -264 19 -19 33 -20 293 -20 253 0 275 1 290 18 15 16 17 50 17 269 0 221 -2 252 -17 264 -12 11 -75 15 -283 17 -258 3 -267 2 -293 -18z m143 -265 c0 -139 -2 -167 -15 -171 -8 -4 -22 -1 -30 6 -12 10 -15 42 -15 165 0 83 3 155 7 158 3 4 17 7 30 7 l23 0 0 -165z m170 0 c0 -139 -2 -167 -15 -171 -8 -4 -22 -1 -30 6 -12 10 -15 42 -15 165 0 83 3 155 7 158 3 4 17 7 30 7 l23 0 0 -165z m170 0 c0 -139 -2 -167 -15 -171 -8 -4 -22 -1 -30 6 -12 10 -15 42 -15 165 0 83 3 155 7 158 3 4 17 7 30 7 l23 0 0 -165z" />
                  <path d="M1266 2019 l-26 -20 0 -245 c0 -231 1 -245 20 -264 19 -19 33 -20 293 -20 220 0 277 3 293 14 18 13 19 30 22 260 l3 246 -25 25 -25 25 -264 0 c-252 0 -266 -1 -291 -21z m142 -266 c-3 -160 -4 -168 -23 -168 -19 0 -20 8 -23 168 l-2 167 25 0 25 0 -2 -167z m170 0 c-3 -160 -4 -168 -23 -168 -19 0 -20 8 -23 168 l-2 167 25 0 25 0 -2 -167z m170 0 c-3 -160 -4 -168 -23 -168 -19 0 -20 8 -23 168 l-2 167 25 0 25 0 -2 -167z" />
                  <path d="M2009 2021 c-24 -19 -24 -21 -27 -247 -2 -126 -1 -238 2 -250 14 -54 16 -54 312 -54 261 0 275 1 294 20 19 19 20 33 20 264 l0 245 -26 20 c-25 20 -39 21 -289 21 -244 0 -264 -1 -286 -19z m149 -262 c2 -130 0 -158 -13 -169 -8 -7 -22 -10 -30 -6 -13 4 -15 32 -15 171 l0 166 28 -3 27 -3 3 -156z m170 0 c2 -130 0 -158 -13 -169 -8 -7 -22 -10 -30 -6 -13 4 -15 32 -15 171 l0 166 28 -3 27 -3 3 -156z m170 0 c2 -130 0 -158 -13 -169 -8 -7 -22 -10 -30 -6 -13 4 -15 32 -15 171 l0 166 28 -3 27 -3 3 -156z" />
                  <path d="M2750 2027 l-25 -13 -3 -253 c-2 -226 0 -256 14 -273 16 -17 37 -18 288 -18 230 0 275 2 294 16 22 15 22 18 22 263 0 198 -3 251 -14 267 -14 18 -30 19 -283 22 -203 2 -274 -1 -293 -11z m138 -274 l-3 -168 -25 0 -25 0 -3 168 -2 167 30 0 30 0 -2 -167z m170 0 l-3 -168 -25 0 -25 0 -3 168 -2 167 30 0 30 0 -2 -167z m170 0 l-3 -168 -25 0 -25 0 -3 168 -2 167 30 0 30 0 -2 -167z" />
                  <path d="M3490 2027 l-25 -13 -3 -253 c-2 -226 0 -256 14 -273 16 -17 37 -18 288 -18 230 0 275 2 294 16 22 15 22 18 22 263 0 198 -3 251 -14 267 -14 18 -30 19 -283 22 -203 2 -274 -1 -293 -11z m138 -274 l-3 -168 -25 0 -25 0 -3 168 -2 167 30 0 30 0 -2 -167z m170 0 l-3 -168 -25 0 -25 0 -3 168 -2 167 30 0 30 0 -2 -167z m170 0 l-3 -168 -25 0 -25 0 -3 168 -2 167 30 0 30 0 -2 -167z" />
                  <path d="M1275 1348 c-11 -6 -23 -16 -27 -22 -4 -6 -8 -120 -8 -253 0 -230 1 -244 20 -263 19 -19 33 -20 294 -20 l275 0 20 26 c20 25 21 39 21 261 l0 234 -25 24 -24 25 -263 0 c-177 -1 -270 -4 -283 -12z m135 -273 l0 -165 -25 0 -25 0 0 165 0 165 25 0 25 0 0 -165z m170 0 l0 -165 -25 0 -25 0 0 165 0 165 25 0 25 0 0 -165z m170 0 l0 -165 -25 0 -25 0 0 165 0 165 25 0 25 0 0 -165z" />
                  <path d="M2005 1335 l-25 -24 0 -234 c0 -222 1 -236 21 -261 l20 -26 275 0 c261 0 275 1 294 20 19 19 20 33 20 263 0 133 -4 247 -8 253 -20 30 -55 34 -312 34 l-261 0 -24 -25z m150 -260 l0 -160 -27 -3 -28 -3 0 166 0 166 28 -3 27 -3 0 -160z m170 0 l0 -160 -27 -3 -28 -3 0 166 0 166 28 -3 27 -3 0 -160z m170 0 l0 -160 -27 -3 -28 -3 0 166 0 166 28 -3 27 -3 0 -160z" />
                  <path d="M2742 1344 c-22 -15 -22 -18 -22 -269 0 -251 0 -254 22 -269 19 -14 64 -16 288 -16 224 0 269 2 288 16 22 15 22 18 22 269 0 251 0 254 -22 269 -19 14 -64 16 -288 16 -224 0 -269 -2 -288 -16z m148 -269 l0 -165 -30 0 -30 0 0 165 0 165 30 0 30 0 0 -165z m170 0 l0 -165 -30 0 -30 0 0 165 0 165 30 0 30 0 0 -165z m170 0 l0 -165 -30 0 -30 0 0 165 0 165 30 0 30 0 0 -165z" />
                  <path d="M2742 664 c-22 -15 -22 -18 -22 -269 0 -251 0 -254 22 -269 19 -14 64 -16 288 -16 224 0 269 2 288 16 22 15 22 18 22 269 0 251 0 254 -22 269 -19 14 -64 16 -288 16 -224 0 -269 -2 -288 -16z m132 -100 c14 -5 16 -30 16 -170 l0 -164 -30 0 -30 0 0 164 c0 122 3 166 13 169 6 3 13 6 14 6 1 1 8 -2 17 -5z m170 0 c14 -5 16 -30 16 -170 l0 -164 -30 0 -30 0 0 164 c0 122 3 166 13 169 6 3 13 6 14 6 1 1 8 -2 17 -5z m170 0 c14 -5 16 -30 16 -170 l0 -164 -30 0 -30 0 0 164 c0 122 3 166 13 169 6 3 13 6 14 6 1 1 8 -2 17 -5z" />
                </g>
                <g id="layer103" fill="#fefeff" stroke="none">
                  <path d="M1785 4189 c-497 -40 -877 -168 -1110 -375 l-40 -35 70 6 c80 7 273 -8 385 -31 123 -26 258 -72 378 -131 62 -30 114 -53 116 -52 24 38 126 168 165 211 145 158 401 328 590 391 l43 14 -47 6 c-63 9 -424 6 -550 -4z" />
                  <path d="M1845 3324 c-149 -77 -152 -279 -4 -354 126 -65 282 31 282 173 0 116 -80 197 -195 197 -29 0 -66 -7 -83 -16z m143 -84 c34 -21 52 -54 52 -95 0 -21 -4 -24 -27 -19 -41 8 -67 -16 -66 -60 1 -41 -8 -44 -61 -22 -45 19 -66 52 -66 102 0 91 90 142 168 94z" />
                </g>
              </svg>
            </div>

            <div className="column is-three-quarters">
              <h1 className="title c-hero-title is-1 pt-5">
                Docker for Developers
              </h1>
              <p className="subtitle is-4 has-text-link has-text-weight-light pt-3">
                Научете <em>Docker</em> и како да го користите за да го забрзате и
                олесните процесот на развивање на апликации.
              </p>
              <a href="#apply" className="button is-link is-outlined">
                Пријавете се
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container is-max-desktop">
        <div className="columns">
          <div className="column">
            <h3 className="subtitle has-text-grey-dark has-text-weight-light pb-5">
            <em>Docker</em> е платформа со која значително се олеснува и забрзува
              целокупниот развоен процес на апликации со користење на
              контејнери. Во овој курс наменет за програмери започнувајќи од
              основите на <em>Docker</em>, и контејнерите воопшто, ќе научиме како да ги
              користиме најразличните <em>Docker</em> алатки во текот на кодирањето,
              испорачувањето и извршувањето на апликациите.
            </h3>
          </div>
        </div>

        <div className="c-course">
          { children }
        </div>
      </div>
    </section>
  </>
)

export default Wrapper
