import React from "react"

const Sidemenu = () => (
  <div className="c-course-menu">
    <aside className="menu">
      <p className="menu-label">Docker for Developers</p>
      <ul className="menu-list">
        <li>
          <a href="#course-content">Програма</a>
        </li>
        <li>
          <a href="#apply">Пријавување</a>
        </li>
        <li>
          <a href="#faq">ЧПП</a>
        </li>
      </ul>
    </aside>
  </div>
)

export default Sidemenu
