import React from "react"
// import React, { useState } from "react"
// import validator from "validator"
// import { fields, Field } from "./formFields"

/*
const ApplicationFormSubmit = () => {
  const formFields = Object.keys(fields)

  const [form, updateForm] = useState(
    formFields.reduce((acc, f) => {
      acc[f] = ""
      return acc
    }, {})
  )

  const [formErrors, updateFormErrors] = useState(
    formFields.reduce((acc, f) => {
      acc[f] = ""
      return acc
    }, {})
  )

  const [isFormLoading, setIsFormLoading] = useState(false)
  const [faqRead, setFaqRead] = useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const getRecaptchaToken = () => {
    return window.grecaptcha.execute(process.env.GATSBY_RECAPTCHA_CLIENT_KEY, {
      action: "homepage",
    })
  }

  const onFormFieldChange = (fieldName, val) => {
    updateForm({
      ...form,
      [fieldName]: val,
    })
  }

  const validateField = (
    fieldKey,
    value,
    errorMsg,
    validator,
    ...validatorArgs
  ) => {
    if (!validator(value, ...validatorArgs)) {
      updateFormErrors({
        ...formErrors,
        [fieldKey]: errorMsg,
      })
    } else {
      updateFormErrors({
        ...formErrors,
        [fieldKey]: "",
      })
    }
  }

  const isFormValid = () => {
    return formFields.every(field => {
      const { validateOp, validateArgs } = fields[field]
      const value = form[field]

      console.log(validator[validateOp](value, validateArgs))

      return validator[validateOp](value, validateArgs)
    })
  }

  const submitForm = async e => {
    e.preventDefault()

    if (!faqRead) {
      return false
    }

    if (!isFormValid()) {
      return false
    }

    updateFormErrors(
      formFields.reduce((acc, f) => {
        acc[f] = ""
        return acc
      }, {})
    )

    const token = await getRecaptchaToken()
    setIsFormLoading(true)

    try {
      const { success, errors = [] } = await fetch(
        `${process.env.GATSBY_API_URL}/docker-for-developers/submit-application`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...form,
            token,
          }),
        }
      ).then(r => r.json())

      if (success) {
        setIsFormSubmitted(true)
      } else {
        updateFormErrors({
          ...formFields.reduce((acc, f) => {
            acc[f] = ""
            return acc
          }, {}),
          ...errors.reduce((acc, { param, msg }) => {
            if (param && msg) {
              acc[param] = msg
            }
            return acc
          }, {}),
        })
      }
    } catch (err) {
      console.log(`err`)
      console.log(err)
    } finally {
      setIsFormLoading(false)
    }
  }

  return (
    <div className="content">
      <h3 id="apply" className="title is-4 is-spaced c-anchor-title">
        <span className="c-anchor-name">Пријавување</span>
        <a className="c-anchor-link" href="#apply">
          #
        </a>
      </h3>

      {isFormSubmitted ? (
        <article className="message is-link">
          <div className="message-body">
            <strong className="is-size-5">
              Пријавата е успешно поднесена!{" "}
              <span role="img" aria-label="success">
                🎉
              </span>
            </strong>
            <br />
            <br />
            Испратена е порака за потврда на Вашата електронска адреса. Може да
            пристигне и за неколку минути, Ве молам проверете и во спам.
          </div>
        </article>
      ) : (
        <form onSubmit={submitForm}>
          <p className="has-text-weight-light mb-5">
            <em>
              * Сите полиња се задолжителни. Пред да се пријавите осигурајте се
              дека ја имате прочитано секцијата за <a href="#faq">ЧПП</a>.
            </em>
          </p>

          {Object.keys(fields).map(field => (
            <Field
              key={field}
              {...{
                ...fields[field],
                field,
                onFormFieldChange,
                validateField,
                value: form[field],
                error: formErrors[field],
              }}
            />
          ))}

          <div className="field">
            <div className="control">
              <label className="checkbox" htmlFor="faqRead">
                <input
                  type="checkbox"
                  checked={faqRead}
                  id="faqRead"
                  onChange={e => {
                    setFaqRead(e.target.checked)
                  }}
                />{" "}
                Ги прочитав <a href="#faq">ЧПП</a>
              </label>
            </div>
          </div>

          <div className="field">
            <div className="control">
              {isFormLoading ? (
                <span className="button is-link is-loading" />
              ) : (
                <button
                  type="submit"
                  className="button is-link"
                  disabled={!faqRead}
                >
                  Пријавете се
                </button>
              )}
            </div>
          </div>
        </form>
      )}
    </div>
  )
}
*/

const ApplicationForm = () => {

  return (
    <div className="content">
      <h3 id="apply" className="title is-4 is-spaced c-anchor-title">
        <span className="c-anchor-name">Пријавување</span>
        <a className="c-anchor-link" href="#apply">
          #
        </a>
      </h3>

        <article className="message is-danger">
          <div className="message-body">
            <strong className="is-size-5">
              Пријавувањето е завршено!
            </strong>
          </div>
        </article>
    </div>
  )
}

export default ApplicationForm
